// For clarity, imports are ordered according to flow (order in which the pages are viewed)



import Products from "./pages/Products.vue";
import Order    from "./pages/Order.vue";

const routes =
    [


        {
            path: "/en/:client/:catalog/products",
            name: "products-en",
            component: Products
        },

        {
            path: "/fr/:client/:catalog/produits",
            name: "products-fr",
            component: Products
        },

        {
            path: "/en/:client/:catalog/order",
            name: "order-en",
            component: Order
        },

        {
            path: "/fr/:client/:catalog/commande",
            name: "order-fr",
            component: Order
        },


    ]


export default routes;
