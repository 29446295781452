<script setup>

let props = defineProps(["total"]);
</script>

<template>
    <div class="row y-gap-20 justify-between items-center md:d-flex">
        <div class="col-12">
            <div class="d-flex justify-end">
                <p>Total:&nbsp;</p>
                <p>{{ total }}&nbsp;$</p>
            </div>
        </div>
    </div>


</template>

<style scoped>

</style>
