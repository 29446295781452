<script setup>

import Products_list from "../components/products_list.vue";
import {useMainStore} from "../stores/main";
import {storeToRefs} from "pinia";
import Not_available from "../components/not_available.vue";

let store = useMainStore();
const { ready, lang, mode, catalog } = storeToRefs(store)

</script>

<template>

    <products_list
        :pathSubmit="{name: `order-${lang}`}"
    ></products_list>
</template>

<style scoped>

</style>
