<script setup>

import {useMainStore} from "../stores/main";
import {storeToRefs}  from "pinia";

let store = useMainStore();
let {   copydeck, lang,  } = storeToRefs(store);


let props = defineProps(["order", "total"]);

</script>

<template>
    <div class="pt-30 pb-15 bg-white border-light rounded-8 bg-light-4">
        <h5 class="px-30 text-20 fw-500">
            {{ copydeck.invoice.your_order[lang] }}
        </h5>

        <div class="d-flex justify-between px-30 mt-25">
            <div class="py-15 fw-500 text-dark-1">{{ copydeck.invoice.products[lang] }}</div>
            <div class="py-15 fw-500 text-dark-1">{{ copydeck.invoice.subtotal[lang] }}</div>
        </div>

        <div class="d-flex justify-between border-top-dark px-30" v-for="item in order">
            <div class="py-15 text-grey">{{ item.product.name }}{{ item.quantity > 1 ? ` (x${item.quantity})` : ""}}</div>
            <div class="py-15 text-grey">{{ item.sub}}&nbsp;$</div>
        </div>


        <div class="d-flex justify-between border-top-dark px-30">
            <div class="py-15 fw-500 text-dark-1">{{ copydeck.invoice.total[lang] }}</div>
            <div class="py-15 fw-500 text-dark-1">{{ parseFloat(total).toFixed(2) }}&nbsp;$</div>
        </div>
    </div>
</template>

<style scoped>

</style>
