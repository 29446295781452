<script setup>

    import { onMounted, ref, watch  }    from "vue";
    import { useMainStore }                 from "../stores/main";
    import { storeToRefs }                  from "pinia";

    let store = useMainStore();
    let { ready, copydeck, lang, mode} = storeToRefs(store);


    onMounted(async () =>
    {
        //setTimeout( () => mode.value = 'ordered', 2000);
    })

</script>

<template>
    <section class="page-header -type-1">
        <div class="container">
            <div class="page-header__content">
                <div class="row justify-center text-center">
                    <div class="col-auto">
                        <div data-anim="">

                            <h1 class="page-header__title"> {{ copydeck.processing.title[lang] }}</h1>

                        </div>

                        <div data-anim="">

                            <p class="page-header__text">{{ copydeck.processing.subtitle[lang] }}</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


</template>

<style scoped>

</style>
