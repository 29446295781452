import { defineStore } from 'pinia';
import { computed, inject, ref, shallowRef, watch } from "vue";
import { useStorage }        from "@vueuse/core";
import {useRoute, useRouter} from "vue-router";

let router;
let route;

export const useMainStore = defineStore('main', () =>
{
    let router   = useRouter();
    let route    = useRoute();

    const availableLang = ["en", "fr"];
    const lang = shallowRef([]);
    lang.value  = inject( 'lang' );

    const otherURL = shallowRef("");
    otherURL.value  = inject( 'otherURL' );

    const copydeck = shallowRef([]);
    copydeck.value = inject('copydeck');


    const catalog = ref([]);
    catalog.value = inject('catalog');

    const mode = ref("order");

    let userInfo = useStorage('userInfo', {}, localStorage);
    let currentOrder = useStorage('currentOrder', [], localStorage);

    watch(userInfo, (value) =>
    {
        console.log("userInfo changed", value);
    });

    const userInfoErrors = ref ( {} );
    const invoice = ref ( {} );

    console.log("define store");


    const ready = ref(true); //if we need to load something, initialize at false and set to true when loading completed

    function resetOrder ()
    {
        console.log("resetOrder");
        currentOrder.value = [];
        userInfo.value = {};
    }
    function changeLang ( strLang )
    {
        if (availableLang.includes(strLang))
        {
            lang.value = strLang;
        }
    }

    function setLangFromRoute ()
    {
        let routeLang = route.params.lang ?? "";

        if (["en","fr"].includes(routeLang))
        {
            changeLang(routeLang);
        }
    }

    let order = computed(() =>
                         {
                             let list = [];

                             for (let i = 0; i < catalog.value.groups.length; i++)
                             {
                                 let group = catalog.value.groups[i];

                                 for (let j = 0; j < group.products.length; j++)
                                 {
                                     let product = group.products[j];
                                     let order = currentOrder.value.find ( (item) => item.id === product.id);

                                     if (order && order.quantity > 0)
                                     {
                                         let quantity = order.quantity;
                                         let sub = (quantity  * product.price).toFixed(2);
                                         list.push({product, quantity, sub});


                                     }
                                 }


                             }


                             return list;
                         });

    let total = computed(() =>
                         {
                                let total = 0;

                                 for (let i = 0; i < catalog.value.groups.length; i++)
                                 {
                                     let group = catalog.value.groups[i];

                                     for (let j = 0; j < group.products.length; j++)
                                     {
                                         let product = group.products[j];
                                         let order = currentOrder.value.find ( (item) => item.id === product.id);

                                         if (order && order.quantity > 0)
                                         {
                                             let sub = order.quantity * product.price;
                                             total += sub;
                                         }
                                     }


                                 }

                                return total.toFixed(2);
                         });



    let formIsReady = computed ( () =>
                                 {

                                     let mandatory = ["first_name", "last_name", /*"address", "city", "postal_code", "phone",*/ "email"];


                                     //check for value in userInfo for mandatory field

                                     let countField = 0;

                                     for (let key in userInfo.value)
                                     {

                                         let value = userInfo.value[key];

                                         let hasValue =  (value != null && value !== undefined && value.trim() !== "");

                                         if (hasValue)
                                         {
                                             countField++;
                                         }
                                     }

                                     return mandatory.length <= countField;
                                 });


    function getProductById (id)
    {
        for (let i = 0; i < catalog.value.groups.length; i++)
        {
            let group = catalog.value.groups[i];

            for (let j = 0; j < group.products.length; j++)
            {
                let product = group.products[j];

                if (product.id === id)
                {
                    return product;
                }
            }
        }

        return null;
    }

    async function submitOrder (client)
    {
        mode.value = "processing";


        let products = order.value.map ( (item) =>
                                         {
                                             let order = currentOrder.value.find ( (itemOrder) => itemOrder.id === item.product.id);

                                             return {
                                                 id: item.product.id,
                                                 product: {
                                                     name: item.product.name,
                                                     price: item.product.price,
                                                 },
                                                 quantity: order.quantity,
                                                 sub: (order.quantity * item.product.price).toFixed(2)
                                             }
                                         });
        let payload = {
            order: products,
            userInfo: client,
            catalog: catalog.value.id,
            total: parseFloat(total.value).toFixed(2),
            lang: lang.value

        };

        let response = await fetch("/endpoints/submit-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });


        let data = await response.json();

        if (data.success)
        {
            resetOrder();
            mode.value = "ordered";
            invoice.value = data.invoice;
        }
        else
        {
            mode.value = "recap";
            userInfoErrors.value = data.error;
        }

        console.log(data);

    }

    return {
        lang,
        copydeck,
        ready,
        router,
        route,
        catalog,
        total,
        mode,
        order,
        formIsReady,
        userInfo,
        userInfoErrors,
        currentOrder,
        invoice,
        otherURL,

        resetOrder,
        submitOrder,
        getProductById

    };
})
