<script setup>

import Processing from "../components/processing.vue";
import Ordered    from "../components/ordered.vue";
import Recap      from "../components/recap.vue";
import {storeToRefs} from "pinia";
import {useMainStore} from "../stores/main";
import {onMounted} from "vue";
import Not_available from "../components/not_available.vue";

let store = useMainStore();
const { ready, lang, mode, catalog, total } = storeToRefs(store)
const { router } = store;


onMounted ( () =>
            {
                console.log("mounted order total", total.value);

                if (total.value <= 0)
                {
                    router.push({name: "products-" + lang.value});
                    return;
                }
                mode.value = "recap";
            })

</script>

<template>
    <not_available v-if="mode == 'problem'"></not_available>
    <recap v-if="mode == 'recap'"></recap>
    <processing v-if="mode == 'processing'"></processing>
    <ordered v-if="mode == 'ordered'"></ordered>
</template>

<style scoped>

</style>
