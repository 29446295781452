<template>

    <section class="layout-pt-md layout-pb-lg">


        <div class="container">

            <div class="row justify-end">
                <div class="col-12">

                    <div class="d-flex justify-content-end">
                        <a :href="otherURL">{{ otherLang }}</a>
                    </div>
<!--                    <img-->

<!--                        :srcset="catalogHeaderSrcSet"-->
<!--                        :sizes="catalogHeaderSizes"-->
<!--                        :src="catalog.image_mobile[lang]" alt="" style="width:100%" />-->

                    <picture>

                        <source media="(max-width: 767px)" :srcset="catalog.image_mobile[lang]">
                        <source media="(min-width: 768px)" :srcset="catalog.image[lang]">

                        <img :src="catalog.image[lang]">
                    </picture>

                    <div id="scrollGoal"></div>


                    <router-view
                        :key="$route.name">

                    </router-view>

                </div>

            </div>
        </div>


    </section>

</template>

<script>

    export default
    {
        name: "App"
    }

</script>


<script setup>

    import { onMounted, ref, watch, computed  }    from "vue";
    import { useMainStore }                 from "./stores/main";
    import { storeToRefs }                  from "pinia";
    import { useTitle }                     from '@vueuse/core';
    import Products_list                    from "./components/products_list.vue";
    import Recap                            from "./components/recap.vue";
    import { useScroll } from '@vueuse/core'
    import Ordered from "./components/ordered.vue";
    import Processing from "./components/processing.vue";


    let store = useMainStore();

    const { ready, lang, mode, catalog, otherURL  } = storeToRefs(store)
    const { route, router} = store

    let title = useTitle();
    title.value = catalog.value.name;


    let otherLang = ref(lang.value === "fr" ? "English" : "Français");

    let catalogHeaderSrcSet = computed ( () => {
        return `${catalog.value.image_mobile[lang.value]} 767w ${catalog.value.image[lang.value]} 1320w`
    })

    let catalogHeaderSizes = computed ( () => {
        return `(max-width: 767px) 767px, 1320px`
    })

    watch (mode, (val) =>
    {
        let element = document.getElementById('scrollGoal');
        var scrollTargetY = element.offsetTop;

        scrollTo(0,scrollTargetY);
    })
    //
    // router.beforeEach((to, from, next) =>
    // {
    //     let element = document.getElementById('scrollGoal');
    //     var scrollTargetY = element.offsetTop;
    //
    //     scrollTo(0,scrollTargetY);
    //
    //     return true;
    // })




    onMounted(async () =>
    {

    })


</script>


<style scoped>

</style>
