<script setup>

import {computed} from "vue";
import Product from "./product.vue";
import Products_list_head from "./products_list_head.vue";
import {useMainStore} from "../stores/main";
import {storeToRefs} from "pinia";
import Products_list_total from "./products_list_total.vue";
import Order_details from "./order_details.vue";


let store = useMainStore();
let { order, total, mode, copydeck, lang, userInfo, formIsReady, catalog } = storeToRefs(store);
let { submitOrder } = store;



</script>

<template>

        <section class="page-header -type-1">
            <div class="container">
                <div class="page-header__content">
                    <div class="row justify-center text-center">
                        <div class="col-auto max-w-50-md">
                            <div data-anim="">

                                <h1 class="page-header__title"> nous expérimentons des problèmes actuellement</h1>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>
       

</template>

<style scoped>

</style>
