<script setup>

import Order_details from "./order_details.vue";
import {useMainStore} from "../stores/main";
import {storeToRefs} from "pinia";

let store = useMainStore();
let { invoice, copydeck, lang, catalog } = storeToRefs(store);
</script>

<template>

    <section class="layout-pt-md layout-pb-lg">
        <div class="container">
            <div class="row no-gutters justify-content-center">
                <div class="col-xl-8 col-lg-9 col-md-11">
                    <div class="shopCompleted-header">
                        <div class="icon">
                            <i data-feather="check"></i>
                        </div>
                        <h2 class="title">
                            {{ copydeck.invoice.title[lang] }}
                        </h2>
                        <div class="subtitle">
                            {{ copydeck.invoice.subtitle[lang] }}
                        </div>
                    </div>

                    <div class="shopCompleted-info">
                        <div class="row no-gutters y-gap-32">

                            <div class="col-md-4 col-sm-6">
                                <div class="shopCompleted-info__item">
                                    <div class="subtitle">{{ copydeck.invoice.order_num[lang] }}</div>
                                    <div class="title text-blue-1 mt-5">{{ invoice.invoice_num }}</div>
                                </div>
                            </div>

                            <div class="col-md-4 col-sm-6">
                                <div class="shopCompleted-info__item">
                                    <div class="subtitle">{{ copydeck.invoice.date[lang] }}</div>
                                    <div class="title text-blue-1 mt-5">{{ invoice.date }}</div>
                                </div>
                            </div>

                            <div class="col-md-4 col-sm-6">
                                <div class="shopCompleted-info__item">
                                    <div class="subtitle">{{ copydeck.invoice.total[lang] }}</div>
                                    <div class="title text-blue-1 mt-5">{{ parseFloat(invoice.total).toFixed(2) }} $</div>
                                </div>
                            </div>

<!--                            <div class="col-md-3 col-sm-6">-->
<!--                                <div class="shopCompleted-info__item">-->
<!--                                    <div class="subtitle">Payment Method</div>-->
<!--                                    <div class="title text-blue-1 mt-5">Direct Bank Transfer</div>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>

                    <div class="shopCompleted-footer bg-light-4 border-light rounded-8">
                        <div class="pt-30 pb-15 bg-white border-light rounded-8 bg-light-4">
                            <h5 class="px-30 text-20 fw-500">
                                Informations
                            </h5>

                            <div class="d-flex justify-between px-30 mt-25">
                                <div class="fw-500 text-dark-1"> {{ invoice.client.first_name }} {{ invoice.client.last_name }}</div>
                            </div>

                            <div class="d-flex justify-between px-30 mt-25">
                                <div class="fw-500 text-dark-1"> {{ invoice.client.email }} </div>
                            </div>

                            <div class="d-flex justify-between px-30 mt-5">
                                <div class="fw-500 text-dark-1"> {{ invoice.client.child_name }} <span v-if="invoice.client.classroom">({{ invoice.client.classroom }})</span></div>
                            </div>

                            <div class="d-flex justify-between px-30 mt-5">
                                <div class="fw-500 text-dark-1" v-html="catalog.payment.details[lang] "></div>
                            </div>

                        </div>
                    </div>
                    <div class="shopCompleted-footer bg-light-4 border-light rounded-8">
                        <order_details :order="invoice.order" :total="invoice.total"></order_details>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>

</style>
