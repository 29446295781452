import {createApp} from 'vue'
import {createRouter, createWebHistory} from 'vue-router';
import routes from "./app/routes";
import App from "./app/App";

import {createPinia} from 'pinia';

function onReady ()
{
    const el = document.querySelector("#container");

    const pinia = createPinia()
    const vue = createApp( App );

    const router = createRouter(
        {
            history: createWebHistory(),
            routes
        });

    //decode from base64
    const catalog = JSON.parse(atob(__d__ ?? "e30="));
    const copydeck = JSON.parse(atob(__c__ ?? "e30="));
    const otherURL = (atob(__ou__ ?? "e30="));

    vue
        .use(pinia)
        .use(router)
         .provide( 'lang', __lang__ ?? "en")
         .provide( 'copydeck', copydeck ?? [])
         .provide( 'catalog', catalog ?? [])
         .provide( 'otherURL', otherURL ?? "fr")
        // .provide( 'copydeckAudio', __copydeckAudio__ ?? [])
        // .provide( 'songs', __songs__ ?? [])
        // .provide( 'reactions', __reactions__ ?? [])
        // .provide( 'intros', __intros__ ?? [])
        // .provide( 'avatars', __avatars__ ?? [])
        .mount(el);

    window.postMessage({
        devtoolsEnabled: true,
        vueDetected: true
    }, '*')
}

document.addEventListener( 'DOMContentLoaded', onReady );
